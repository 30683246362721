<template>
  <div :class="`editor `">

        <button @click="genCode">code gen</button>

  </div>
</template>

<script>
export default {
  name: "CodeGenerator",
  components: {

  },
  data: function (params) {
    return {

    };
  },
  mounted() {
    
  },
  props: ["items"],

  methods: {
    genCode: function (passed) {
      console.log('la');
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        let primitive = item.primitive;
        console.log(`<a-${primitive}></a-${primitive}>`);
      }
    },

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.editor {
  width: 100%;
  height: 100%;
  display: flex;
}
.scene-wrap {
  position: relative;
  width: 100%;
  height: 100vh;
}
.panel {
  width: 30%;
  height: 100%;
  padding: 10px;
  z-index: 1;
  background: #383838;
  border: 1px solid black;
  color: white;
}
.mobile {
  flex-direction: column-reverse;
  .panel {
    width: 100%;
    overflow-y: auto;
    display: flex;
  }
  a-scene {
    height: 60vh;
  }
}
.hie-color {
  width: 20px;
  height: 20px;
}
.section {
  padding-bottom: 10px;
  border-bottom: 1px solid black;
}
.wrap {
  display: flex;
  align-items: center;

  h4 {
    margin: 0;
    margin-right: 20px;
    font-size: 13px;
  }
  .inner-wrap {
    display: flex;
    align-items: center;
    label {
      padding-right: 5px;
    }
    input {
      margin-right: 5px;
      width: 100%;
      background: #2a2a2a;
      color: white;
    }
  }
}
.color {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  label {
    margin-right: 48px;
  }
  input {
    width: 100%;
    margin-right: 5px;
  }
}
</style>
