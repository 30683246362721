<template>
  <div class="over-scene-ui">
    <div class="add-wrap menu">
      <button v-for="(btn,i) in buttons" :key="i" @click="openMenu = i+1">
        <span :class="`mdi mdi-${btn}`"></span>
      </button>
      <div v-if="openMenu === 1" class="selection-wrap">
        <button
          class="selection"
          v-for="(item, i) in addMenu.primitives"
          @click="$emit('additem', {item: item, type: 'prim'})"
          :key="i"
        >
          {{ item }}
        </button>
      </div>
      <div v-if="openMenu === 2" class="selection-wrap">
        <button
          class="selection"
          @click="$emit('additem', {item: item, type: 'light'})"
          v-for="(item, i) in addMenu.lights"
          :key="i"
        >
          {{ item }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Editor",

  data: function (params) {
    return {
      isMobile: false,
      openMenu: "",
      addMenu: {
        primitives: ["box", "plane", "sphere", "cylinder", "cone"],
        lights: ["point","spot","directional"],
      },
      buttons: ['shape', 'lightbulb']
    };
  },
  mounted() {
    if (AFRAME.utils.device.isMobile()) {
      this.isMobile = true;
    }
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.editor {
  width: 100%;
  height: 100%;
  display: flex;
}

.over-scene-ui {
  position: absolute;
  top: 0;
  padding: 5px;
  z-index: 1;
  .selection-wrap {
    display: flex;
    flex-direction: column;
    width: 100px;
  }
  button {
    background: #313131;
    color: white;
    border: none;
    border-radius: 2px;
    padding: 5px;

    border: 1px solid #383838;
    .mdi {
      font-size: 30px;
    }
  }
}
</style>
