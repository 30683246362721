<template>
  <a-entity class="grid">
    <a-entity
      :position="`0 0 ${-grid / 2}`"
      :rotation="`0 0 0`"
      class="horizontal"
    >
      <a-entity
        v-for="n in grid"
        :line="`start: -30 0 ${n}; end: 30 0 ${n}; color: ${
          n === grid / 2 ? 'green' : '#4B4B4B'
        }`"
        :key="n"
      ></a-entity>
    </a-entity>
    <a-entity :position="`${-grid / 2} 0 0`" rotation="0 90 0" class="vertical">
      <a-entity
        v-for="n in grid"
        :line="`start: -30 0 ${n}; end: 30 0 ${n}; color: ${
          n === grid / 2 ? 'red' : '#4B4B4B'
        }`"
        :key="n"
      ></a-entity>
    </a-entity>
  </a-entity>
</template>

<script>
export default {
  name: "Grid",
  data: function (params) {
    return {
      grid: 60,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
