<template>
  <a-scene embedded>
    <a-entity
      camera
      id="editor-cam"
      cursor="rayOrigin: mouse"
      raycaster="objects: .object;"
      look-controls
      orbit-controls="target: 0 1.6 -0.5; minDistance: 0.5; maxDistance: 180; initialPosition: 0 5 15"
      position="0 1.6 3"
    ></a-entity>

    <Grid v-if="!exported" />
    <a-entity class="inner-scene">
      <a-entity
        v-for="(item, i) in items"
        :key="i"
        :position="`${item.position.x} ${item.position.y} ${item.position.z}`"
        :rotation="`${item.rotation.x} ${item.rotation.y} ${item.rotation.z}`"
      >
        <a-entity
          v-if="item.primitive"
          :geometry="`${item.primitive ? 'primitive:' + item.primitive : ''}`"
          :material="`color:  ${item.color}`"
          class="object"
          @click="$emit('itemSelect', i)"
          @mouseenter="hover"
          @mouseleave="hoverLeave"
        >
        </a-entity>
        <a-light
          v-if="item.light.type"
          :type="item.light.type"
          :color="item.color"
        ></a-light>
      </a-entity>

      <a-sky color="#3C3C3C"></a-sky>
    </a-entity>
  </a-scene>
</template>

<script>
import Grid from "@/components/misc/Grid.vue";

export default {
  name: "Scene",
  props: ["items"],
  components: {
    Grid,
  },
  data: function (params) {
    return {};
  },
  mounted() {},
  methods: {
    hover: function (e) {
      e.target.setAttribute("material", "transparent: true; opacity: 0.7");
    },
    hoverLeave: function (e) {
      e.target.setAttribute("material", "transparent: true; opacity: 1");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.editor {
  width: 100%;
  height: 100%;
  display: flex;
}
a-scene {
  display: block;
  width: 100%;
  height: 100%;
  //   height: 100vh;
}
.ui {
  width: 30%;
  height: 100%;
  padding: 10px;
  z-index: 1;
  background: #383838;
  border: 1px solid black;
  color: white;
}
.mobile {
  flex-direction: column-reverse;
  .ui {
    height: 40vh;
    width: 100%;
    overflow-y: auto;
  }
  a-scene {
    height: 60vh;
  }
}
.hie-color {
  width: 20px;
  height: 20px;
}
.section {
  padding-bottom: 10px;
  border-bottom: 1px solid black;
}
.wrap {
  display: flex;
  align-items: center;

  h4 {
    margin: 0;
    margin-right: 20px;
    font-size: 13px;
  }
  .inner-wrap {
    display: flex;
    align-items: center;
    label {
      padding-right: 5px;
    }
    input {
      margin-right: 5px;
      width: 100%;
      background: #2a2a2a;
      color: white;
    }
  }
}
.color {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  label {
    margin-right: 48px;
  }
  input {
    width: 100%;
    margin-right: 5px;
  }
}
</style>
