<template>
  <div :class="`editor ${isMobile ? 'mobile' : ''}`">
    <div class="panel" v-if="!exported">
      <CodeGenerator :items="items"/>
      <div class="section hierarchy">
        <h3>Hierarchy:</h3>

        <button
          class="item"
          v-for="(item, i) in items"
          :key="i"
          @click="selItem = items[i]"
          :style="`border: solid 4px ${item.color}`"
        >
          {{ item.primitive }}
        </button>
      </div>
      <div class="section inspector" v-if="selItem">
        <h3>Inspector:</h3>
        <div class="transform">
          <div class="wrap position">
            <h4>Position</h4>
            <div class="inner-wrap">
              <label for="sel-pos-x">x</label>
              <input
                type="number"
                :id="'sel-pos-x'"
                v-model="selItem.position.x"
              />
            </div>
            <div class="inner-wrap">
              <label for="sel-pos-y">y</label>
              <input
                type="number"
                :id="'sel-pos-y'"
                v-model="selItem.position.y"
              />
            </div>
            <div class="inner-wrap">
              <label for="sel-pos-z">z</label>
              <input
                type="number"
                :id="'sel-pos-z'"
                v-model="selItem.position.z"
              />
            </div>
          </div>
          <div class="wrap rotation">
            <h4>Rotation</h4>
            <div class="inner-wrap">
              <label for="sel-rot-x">x</label>
              <input
                type="number"
                :id="'sel-rot-x'"
                v-model="selItem.rotation.x"
              />
            </div>
            <div class="inner-wrap">
              <label for="sel-rot-y">y</label>
              <input
                type="number"
                :id="'sel-rot-y'"
                v-model="selItem.rotation.y"
              />
            </div>
            <div class="inner-wrap">
              <label for="sel-rot-z">z</label>
              <input
                type="number"
                :id="'sel-rot-z'"
                v-model="selItem.rotation.z"
              />
            </div>
          </div>

          <div class="color">
            <label for="color">Color</label
            ><input
              type="color"
              name="color"
              id="color"
              v-model="selItem.color"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="scene-wrap">
      <AddMenu v-if="!exported" :items="items" @additem="addItem($event)" />
      <Scene :items="items" @itemSelect="selItem = items[$event]" />
    </div>
  </div>
</template>

<script>
import Scene from "@/components/Scene.vue";
import AddMenu from "@/components/Editor/AddMenu.vue";
import CodeGenerator from "@/components/Editor/CodeGenerator.vue";

export default {
  name: "Editor",
  components: {
    Scene,
    AddMenu,
    CodeGenerator,
  },
  data: function (params) {
    return {
      isMobile: false,
      exported: false,
      items: [],
      primSelect: "",
      selItem: null,
      newItem: {
        primitive: "",
        light: { type: "", intensity: 1 },
        color: "",
        position: { x: 0, y: 0, z: 0 },
        rotation: { x: 0, y: 0, z: 0 },
      },
      transform: ["position", "rotation", "scale"],
    };
  },
  mounted() {
    if (AFRAME.utils.device.isMobile()) {
      this.isMobile = true;
    }
    document.addEventListener("keydown", this.jump, false);
    // document.addEventListener("keyup", this.land, false);
  },
  methods: {
    genCode: function (passed) {
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        let primitive = item.primitive;
        console.log(`<a-${primitive}></a-${primitive}>`);
      }
    },
    addItem: function (passed) {
      console.log(passed);
      if (passed.type === "prim") this.newItem.primitive = passed.item;
      if (passed.type === "light") this.newItem.light.type = passed.item;
      this.items.push(this.newItem);
      this.newItem = {
        primitive: "",
        light: { type: "", intensity: 1, color: "" },
        color: "",
        position: { x: 0, y: 0, z: 0 },
        rotation: { x: 0, y: 0, z: 0 },
      };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.editor {
  width: 100%;
  height: 100%;
  display: flex;
}
.scene-wrap {
  position: relative;
  width: 100%;
  height: 100vh;
}
.panel {
  width: 30%;
  height: 100%;
  padding: 10px;
  z-index: 1;
  background: #383838;
  border: 1px solid black;
  color: white;
}
.mobile {
  flex-direction: column-reverse;
  .panel {
    width: 100%;
    overflow-y: auto;
    display: flex;
  }
  a-scene {
    height: 60vh;
  }
}
.hie-color {
  width: 20px;
  height: 20px;
}
.section {
  padding-bottom: 10px;
  border-bottom: 1px solid black;
}
.wrap {
  display: flex;
  align-items: center;

  h4 {
    margin: 0;
    margin-right: 20px;
    font-size: 13px;
  }
  .inner-wrap {
    display: flex;
    align-items: center;
    label {
      padding-right: 5px;
    }
    input {
      margin-right: 5px;
      width: 100%;
      background: #2a2a2a;
      color: white;
    }
  }
}
.color {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  label {
    margin-right: 48px;
  }
  input {
    width: 100%;
    margin-right: 5px;
  }
}
</style>
