<template>
  <div id="app">
    <Main/>
  </div>
</template>

<script>
import Main from './components/Editor.vue'

export default {
  name: 'App',
  components: {
    Main
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
html {
  height: 100%;
  width: 100%;
}
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}
</style>
